#projects {
  position: relative;
  background-color: $dark-mode;
  padding-bottom: $small-space * 2;
  padding-top: $small-space * 2;

  .section-heading {
    padding-top: $small-space;
    margin-bottom: $small-space;
    font-size: 5vw;
    @media screen and (max-width: 750px) {
      padding-bottom: 10px;
    }

    color: $white;
  }

  #slider-container {
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;

    /* Using flexbox */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    /*----*/
  }

  .container {
    max-width: $max-width;
    margin: $large-space auto;
  }

  #hook-text {
    color: transparent;
    background: radial-gradient(50% 50% at 50% 50%, #faff00 0%, #ff9900 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media screen and (max-width: 750px) {
    .grid-item {
      width: 68vw;
    }
  }
}

.slick-track {
  @include flex(row nowrap, center, center);
}

#project-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 1%;
  justify-content: center;
  width: 65%;
  gap: 20px;
  @media screen and (max-width: 750px) {
    width: 60%;
  }
}

#text-container {
  display: flex;
  width: 27%;
  margin-left: 3%;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 750px) {
    width: 35%;
  }
}
.flip-card {
  width: 280px;
  height: 400px;
  @media screen and (max-width: 750px) {
    width: 90px;
    height: 150px;
  }
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  -webkit-backface-visibility: hidden;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  @media screen and (max-width: 750px) {
    font-size: 4.5px;
    #projectImage {
      width: 30px !important;
    }
  }
}

.flip-card-front {
  //background-color: var(--cardColor);
  color: white;
}

.flip-card-back {
  background-color: #2980b9;
  background: radial-gradient(#000000, #0b9872);
  color: white;
  transform: rotateY(180deg);
}
.body {
  margin: $medium-space 0;
  margin-top: 0px;
  font-size: $medium-font-size + 2;
  text-align: center;
  color: white;
  @media screen and (max-width: 750px) {
    font-size: $base-font-size - 4;
  }
}
