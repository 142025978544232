/* Color Palette */
$dark-gray: #535152;
$light-gray: #696e76;
$green: #00e174;
$white: #ffffff;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Poppins:wght@400;600&display=swap");

$background-color: hsl(339, 77%, 99%);
$transparent-background-color: hsla(339, 77%, 99%, 0.65);
$text-color: black;
$blue: #a7dde8;
$pink: #e51b5d;
$orange: #f46e20;
$amaranth: #ef3e65;
$offwhite: #edf5ee;
$red: #dd3b3b;
$yellow: #ffc623;

/* Color Palette 2022 */
$lighter-green: #e5ffea;
$light-green: #a4ecd2;
$main-green: #51e1ad;
$dark-green: #0cb08a;
$darker-green: #15766a;
$dark-mode: #111827;

//$background-color: #edf5ee;

/* Font Styles */
$base-font-family: "Poppins", proxima-nova, sans-serif;
$base-font-size: 15px;
$heavy-font-weight: 700;
$medium-font-size: $base-font-size + 2;
$heading-font-size: $base-font-size * 4;
$subheading-font-size: $base-font-size * 1.5;

/* Base Styles */
$small-space: 2vw;
$medium-space: 5vh;
$large-space: 7.5vw;
$transition-speed: 0.2s;
$border-style: 2px solid;
$max-width: 960px;

@mixin flex($flex-flow, $justify, $align) {
  display: flex;
  flex-flow: $flex-flow;
  justify-content: $justify;
  align-items: $align;
}

.logo-text-tree {
  font-family: $base-font-family;
}

.logo-text-hacks {
  font-family: $base-font-family;
  font-weight: $heavy-font-weight;
}

h1.section-heading {
  font-size: $heading-font-size;
  text-transform: lowercase;
  text-align: center;

  @media screen and (max-width: 1023px) {
    padding: 3rem 0 1.5rem;
    font-size: $heading-font-size * 0.75;
  }
}

h2.section-subheading {
  font-size: $subheading-font-size;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

.apply-button {
  border: $border-style #00000000;
  box-sizing: border-box;
  background-color: $dark-green;
  color: white;
  display: block;
  font-weight: $heavy-font-weight;
  font-size: $base-font-size * 1.75;
  padding: 1vh 0;
  text-align: center;
  transition: all $transition-speed;

  &.subtle {
    background: transparent;
    color: #fff;
    font-size: $base-font-size * 1.1;
    border: $border-style $main-green;
  }
}
