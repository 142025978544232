#sponsors.container {
  //background-color: #1f2937;
  background: $dark-mode;
  // padding-top: $large-space;
  padding-bottom: $small-space;
  @include flex(column nowrap, flex-start, center);
  min-height: 300px;
  justify-content: center;

  .section-heading {
    margin-bottom: $small-space;
    font-size: $heading-font-size;
    // padding-top: 100px;
  }

  .body {
    margin: $medium-space 0;
    margin-top: 20px;
    margin-right: 100px;
    margin-left: 100px;
    font-size: $medium-font-size + 6;
    text-align: center;
  }
  #sub-heading {
    margin-bottom: 1.5rem;
    color: white;
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    margin-left: 0px;
  }

  .level {
    //@include flex(row wrap, center, center);
    margin: 1rem auto;

    img:hover {
      transition: $transition-speed;
      transform: scale(1.05);
    }

    &.level-biggest img {
      background-color: white;
      width: 600px;
      padding: 20px;
      border-radius: 20px;
    }

    &.level-big img {
      background-color: white;
      border-radius: 20px;
      padding: 20px;
      width: 300px;
      height: 80px;
    }

    &.level-medium img {
      background-color: white;
      padding: 20px;
      border-radius: 20px;
      width: 166px;
      height: 60px;
    }

    &.level-small img {
      background-color: white;
      padding: 20px;
      border-radius: 20px;
      width: 90px;
      height: 40px;
    }

    // &.level-small img {
    //   // max-height: 40px;
    //   // max-width: 100%;
    //   // min-height: 150px;
    //   height: 35px;
    //   // min-width: 60px;
    //   width: auto;
    // }

    &.level-smallest {
      margin-top: 2rem;
    }

    &.level-smallest img {
      width: 100%;
    }

    &.level-smallest a {
      width: 10%;
      min-width: 50px;
      margin: 0 10px;
    }

    &.level-tiny {
      margin-bottom: 3rem;
    }

    &.level-tiny img {
      width: 100%;
    }

    &.level-tiny a {
      width: 6%;
      min-width: 50px;
      margin: 0 10px;
    }

    a {
      margin: 0rem;
    }

    & + .level {
    }
  }
}
