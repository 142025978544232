$navbar-logo-font-size: 36px;
$navbar-logo-image-width: 45px;
$navbar-logo-image-height: 40px;
$navbar-font-size: $base-font-size * 1.18;
$navbar-margin: 0.5vw;
$navbar-horizontal-padding: 15px;

#navbar {
  position: fixed;
  top: 0;
  z-index: 101;

  //background-color: $dark-mode;
  background: rgba(17, 24, 39, 0.9); /*  10% opaque green */
  backdrop-filter: blur(2px);

  height: 80px;
  width: 100vw;

  .container {
    @include flex(row nowrap, center, center);
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 $small-space;
    list-style-type: none;
  }
}

#navbar-logo {
  flex: 1;
  margin-top: -0.5vh;
  font-size: $navbar-logo-font-size;
  padding: 0 $navbar-horizontal-padding 0 $navbar-horizontal-padding;

  a {
    @include flex(row nowrap, flex-start, center);
  }

  img {
    fill: #fff;
    width: $navbar-logo-image-width;
    height: $navbar-logo-image-height;
  }
}

.menu {
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
  list-style-type: none;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
  }
}

.navbar-item {
  margin: 10px $navbar-margin;
  padding: 0 $navbar-horizontal-padding 0 $navbar-horizontal-padding;
  font-size: $navbar-font-size -2;

  a {
    color: $white;
  }

  &:hover a {
    color: $light-green;
  }
}

.special-link a {
  color: $orange;
}

.apply-link {
  color: $white;
  background-color: $darker-green;
  padding: 9px;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: $transition-speed all;
  display: inline-block;

  &:hover,
  &:focus {
    transform: scale(1.05);
    color: #fff;
  }
}

#navbar-apply {
  margin-left: $navbar-margin;
  padding: $navbar-horizontal-padding $navbar-margin * 1.5;
  font-size: $navbar-font-size;
  font-weight: $heavy-font-weight;
  transition: background-color $transition-speed;

  &:hover {
    background-color: $main-green;
  }
}

#title {
  color: $white;
  text-align: right;
  margin-left: 1vw;
}

@media screen and (max-width: 1220px) {
  .menu {
    display: block;
  }

  .navbar-item {
    margin: $navbar-margin;
  }

  #title {
    display: none;
  }

  #navbar-logo {
    display: none;
  }

  .navbar-item.about-link {
    display: none;
  }
}
