#countdown {
  text-align: center;

  .container {
  }

  img {
    margin: 10vw 0 5vw 0;
    width: 35vw;
    transform: rotate(90deg);
  }

  sup {
    vertical-align: 25%;
  }
}

#deadlines {
  @include flex(row nowrap, space-evenly, flex-end);
  align-items: center;
  @media screen and (max-width: 550px) {
    margin: 0 -14vw;
  }
}

.single-deadline {
  color: white;
  margin: -$small-space / 2;
  padding: $small-space / 2;

  h1 {
    text-decoration: none !important;
    color: $dark-green;
    font-size: $heading-font-size * 2;
    @media screen and (max-width: 650px) {
      font-size: $heading-font-size * 0.9;
    }
  }

  p {
    font-size: $medium-font-size;
    @media screen and (max-width: 650px) {
      font-size: $medium-font-size * 0.7;

      .deadline-title {
        display: block;
      }
    }
  }

  .subtext {
    font-weight: bold;
    color: $dark-green;
  }

  .large {
    text-transform: uppercase;
    font-size: $medium-font-size + 5px;
  }

  .days,
  .day {
    text-transform: lowercase;
  }

  .elapsed {
    color: $light-gray;
    text-decoration: line-through;
  }

  transition: $transition-speed all;

  @media screen and (min-width: 551px) {
    &:hover {
      box-shadow: 0px 0px 15px 0px #e4e4e4;
      border-radius: 20px;
      transform: scale(1.1);
      background-color: #fff;
      color: black;
    }
  }
}

.elapsed {
  &:hover {
    box-shadow: none;
    transform: none;
    background-color: $background-color;
  }
}
