@import url("https://cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css");

.emailSignup form {
  padding: 3vh 0;
  margin-bottom: 1rem;

  div[aria-hidden="true"] {
    position: absolute;
    left: -5000px;
  }
  #mce-EMAIL {
    background-color: inherit;
    border: none;
    border-bottom: 1.5px solid black;
    border-radius: 0;
    color: black;
    width: 400px;
    max-width: 80vw;
    display: block;
    font-family: $base-font-family;
    font-size: 14pt;

    &::placeholder {
      color: $light-gray;
      opacity: 1;
    }
  }
  #mce-EMAIL:focus {
    outline: 0;
  }

  #mc-embedded-subscribe {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    width: 100%;
    max-width: 80vw;
    cursor: pointer;
    -webkit-appearance: none;
    white-space: normal;
  }
}

::placeholder {
  color: #e0e0e0;
  font-style: italic;
}
