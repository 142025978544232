.apply-button {
  margin-top: $small-space / 2;
  background-color: $darker-green;
  padding: 0.5vh 2vw 0.5vh 2vw;
  border-radius: 5px;
  &:hover {
    transform: scale(1.05);
  }
}

#home {
  @include flex(column nowrap, flex-start, center);
  @media screen and (min-width: 450px) {
    height: 100vh;
    width: 100vw;
    min-height: calc(500px + 45vh);
    padding-top: 10vh;
    background: $dark-mode;
  }
  @media screen and (max-width: 450px) {
    margin: $large-space 0;
  }

  img {
    max-height: 35vh;
    max-width: 80vw;
  }
  @media screen and (max-width: 450px) {
    padding-top: 15vh;
    img {
      margin-bottom: 25px;
    }
  }

  .logo {
    min-height: 45vh;
    @media screen and (max-width: 750px) {
      min-height: 20vh;
    }
  }

  #circleLogo {
    padding: 100px;
    padding-top: 150px;
  }

  #gradient_text {
    background: linear-gradient(0deg, #10b981 20.11%, #0ea5e9 150.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    background-clip: text;
    font-family: "Poppins", sans-serif;
    text-fill-color: transparent;
  }

  #stanford-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;

    background: linear-gradient(180deg, #ff0025 50%, #d8d8d8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  #animate1 {
    background-color: red;
  }

  #hacker-text {
    background: linear-gradient(180deg, #347cea 37.63%, #e4e3ff 86.73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
    text-fill-color: transparent;
  }

  #year-text {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.47) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  #floatingIcons {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: row;
  }

  #in-person_text {
    font-weight: 600;
    background: radial-gradient(50% 50% at 50% 50%, #faff00 0%, #ff9900 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  #world-text {
    background: linear-gradient(180deg, #05ff00 37.63%, #e4e3ff 86.73%);
    -webkit-background-clip: text;
    font-weight: 600;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  #next-text {
    background: linear-gradient(180deg, #5200ff 0%, #dc87fa 100%);
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  #arm {
    height: 14vh;
    @media screen and (max-width: 450px) {
      height: 7vh;
    }
    margin-right: auto;
    margin-left: -1vw;
  }

  h1 {
    font-size: 8vh;
    font-weight: normal;
  }

  h2 {
    font-size: $base-font-size * 1.4;
    font-weight: $heavy-font-weight;
    color: $blue;
    max-width: 80vw;

    &.ended {
      font-style: italic;
    }

    @media screen and (max-width: 750px) {
      text-align: center;
    }
  }

  p {
    font-size: 25px;
  }

  #date-text {
    max-width: 90vw;
    text-align: left;
    color: $darker-green;
    font-size: $subheading-font-size;
    font-weight: bold;
    margin-top: 100px;
    padding-bottom: 1vh;
    text-align: center;
    font-size: xx-large;
    // align at bottom
  }

  .rowC {
    @include flex(row wrap, center, flex-start);
  }

  .applylaptop {
    position: relative;
    &:hover {
      transform: scale(1.05);
    }
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.scale-in-center {
  object-fit: contain;
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
