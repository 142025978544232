#test {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: updown;

  @keyframes updown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-40px);
      transform: translateX(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
