@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Poppins:wght@400;600&display=swap);
@import url(https://cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css);
#test {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: updown;
          animation-name: updown; }

@-webkit-keyframes updown {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes updown {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/* Color Palette */
/* Color Palette 2022 */
/* Font Styles */
/* Base Styles */
.logo-text-tree {
  font-family: "Poppins", proxima-nova, sans-serif; }

.logo-text-hacks {
  font-family: "Poppins", proxima-nova, sans-serif;
  font-weight: 700; }

h1.section-heading {
  font-size: 60px;
  text-transform: lowercase;
  text-align: center; }
  @media screen and (max-width: 1023px) {
    h1.section-heading {
      padding: 3rem 0 1.5rem;
      font-size: 45px; } }

h2.section-subheading {
  font-size: 22.5px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold; }

.apply-button {
  border: 2px solid #00000000;
  box-sizing: border-box;
  background-color: #0cb08a;
  color: white;
  display: block;
  font-weight: 700;
  font-size: 26.25px;
  padding: 1vh 0;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .apply-button.subtle {
    background: transparent;
    color: #fff;
    font-size: 16.5px;
    border: 2px solid #51e1ad; }

body {
  background-color: #fefafc;
  color: black;
  font-family: "Poppins", proxima-nova, sans-serif;
  font-size: 15px; }

#root {
  overflow-x: hidden; }

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
nav,
button {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

a {
  color: black;
  text-decoration: none; }
  a:hover {
    cursor: pointer; }

#navbar {
  position: fixed;
  top: 0;
  z-index: 101;
  background: rgba(17, 24, 39, 0.9);
  /*  10% opaque green */
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  height: 80px;
  width: 100vw; }
  #navbar .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 2vw;
    list-style-type: none; }

#navbar-logo {
  flex: 1 1;
  margin-top: -0.5vh;
  font-size: 36px;
  padding: 0 15px 0 15px; }
  #navbar-logo a {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center; }
  #navbar-logo img {
    fill: #fff;
    width: 45px;
    height: 40px; }

.menu {
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
  list-style-type: none;
  text-align: center; }
  .menu li {
    display: inline-block;
    vertical-align: middle; }

.navbar-item {
  margin: 10px 0.5vw;
  padding: 0 15px 0 15px;
  font-size: 17.7px -2; }
  .navbar-item a {
    color: #ffffff; }
  .navbar-item:hover a {
    color: #a4ecd2; }

.special-link a {
  color: #f46e20; }

.apply-link {
  color: #ffffff;
  background-color: #15766a;
  padding: 9px;
  font-weight: bold;
  border-radius: 0.25rem;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  display: inline-block; }
  .apply-link:hover, .apply-link:focus {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    color: #fff; }

#navbar-apply {
  margin-left: 0.5vw;
  padding: 15px 0.75vw;
  font-size: 17.7px;
  font-weight: 700;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  #navbar-apply:hover {
    background-color: #51e1ad; }

#title {
  color: #ffffff;
  text-align: right;
  margin-left: 1vw; }

@media screen and (max-width: 1220px) {
  .menu {
    display: block; }
  .navbar-item {
    margin: 0.5vw; }
  #title {
    display: none; }
  #navbar-logo {
    display: none; }
  .navbar-item.about-link {
    display: none; } }

.apply-button {
  margin-top: 1vw;
  background-color: #15766a;
  padding: 0.5vh 2vw 0.5vh 2vw;
  border-radius: 5px; }
  .apply-button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

#home {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; }
  @media screen and (min-width: 450px) {
    #home {
      height: 100vh;
      width: 100vw;
      min-height: calc(500px + 45vh);
      padding-top: 10vh;
      background: #111827; } }
  @media screen and (max-width: 450px) {
    #home {
      margin: 7.5vw 0; } }
  #home img {
    max-height: 35vh;
    max-width: 80vw; }
  @media screen and (max-width: 450px) {
    #home {
      padding-top: 15vh; }
      #home img {
        margin-bottom: 25px; } }
  #home .logo {
    min-height: 45vh; }
    @media screen and (max-width: 750px) {
      #home .logo {
        min-height: 20vh; } }
  #home #circleLogo {
    padding: 100px;
    padding-top: 150px; }
  #home #gradient_text {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(20.11%, #10b981), color-stop(150.71%, #0ea5e9));
    background: -webkit-linear-gradient(bottom, #10b981 20.11%, #0ea5e9 150.71%);
    background: linear-gradient(0deg, #10b981 20.11%, #0ea5e9 150.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    background-clip: text;
    font-family: "Poppins", sans-serif;
    text-fill-color: transparent; }
  #home #stanford-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ff0025), to(#d8d8d8));
    background: -webkit-linear-gradient(top, #ff0025 50%, #d8d8d8 100%);
    background: linear-gradient(180deg, #ff0025 50%, #d8d8d8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; }
  #home #animate1 {
    background-color: red; }
  #home #hacker-text {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(37.63%, #347cea), color-stop(86.73%, #e4e3ff));
    background: -webkit-linear-gradient(top, #347cea 37.63%, #e4e3ff 86.73%);
    background: linear-gradient(180deg, #347cea 37.63%, #e4e3ff 86.73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
    text-fill-color: transparent; }
  #home #year-text {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(255, 255, 255, 0.47)));
    background: -webkit-linear-gradient(top, #ffffff 0%, rgba(255, 255, 255, 0.47) 100%);
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.47) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; }
  #home #floatingIcons {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: row; }
  #home #in-person_text {
    font-weight: 600;
    background: -webkit-radial-gradient(50% 50%, 50% 50%, #faff00 0%, #ff9900 100%);
    background: radial-gradient(50% 50% at 50% 50%, #faff00 0%, #ff9900 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; }
  #home #world-text {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(37.63%, #05ff00), color-stop(86.73%, #e4e3ff));
    background: -webkit-linear-gradient(top, #05ff00 37.63%, #e4e3ff 86.73%);
    background: linear-gradient(180deg, #05ff00 37.63%, #e4e3ff 86.73%);
    -webkit-background-clip: text;
    font-weight: 600;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; }
  #home #next-text {
    background: -webkit-gradient(linear, left top, left bottom, from(#5200ff), to(#dc87fa));
    background: -webkit-linear-gradient(top, #5200ff 0%, #dc87fa 100%);
    background: linear-gradient(180deg, #5200ff 0%, #dc87fa 100%);
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; }
  #home #arm {
    height: 14vh;
    margin-right: auto;
    margin-left: -1vw; }
    @media screen and (max-width: 450px) {
      #home #arm {
        height: 7vh; } }
  #home h1 {
    font-size: 8vh;
    font-weight: normal; }
  #home h2 {
    font-size: 21px;
    font-weight: 700;
    color: #a7dde8;
    max-width: 80vw; }
    #home h2.ended {
      font-style: italic; }
    @media screen and (max-width: 750px) {
      #home h2 {
        text-align: center; } }
  #home p {
    font-size: 25px; }
  #home #date-text {
    max-width: 90vw;
    text-align: left;
    color: #15766a;
    font-size: 22.5px;
    font-weight: bold;
    margin-top: 100px;
    padding-bottom: 1vh;
    text-align: center;
    font-size: xx-large; }
  #home .rowC {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start; }
  #home .applylaptop {
    position: relative; }
    #home .applylaptop:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.scale-in-center {
  object-fit: contain;
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

#countdown {
  text-align: center; }
  #countdown img {
    margin: 10vw 0 5vw 0;
    width: 35vw;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  #countdown sup {
    vertical-align: 25%; }

#deadlines {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-items: center; }
  @media screen and (max-width: 550px) {
    #deadlines {
      margin: 0 -14vw; } }

.single-deadline {
  color: white;
  margin: -1vw;
  padding: 1vw;
  -webkit-transition: 0.2s all;
  transition: 0.2s all; }
  .single-deadline h1 {
    text-decoration: none !important;
    color: #0cb08a;
    font-size: 120px; }
    @media screen and (max-width: 650px) {
      .single-deadline h1 {
        font-size: 54px; } }
  .single-deadline p {
    font-size: 17px; }
    @media screen and (max-width: 650px) {
      .single-deadline p {
        font-size: 11.9px; }
        .single-deadline p .deadline-title {
          display: block; } }
  .single-deadline .subtext {
    font-weight: bold;
    color: #0cb08a; }
  .single-deadline .large {
    text-transform: uppercase;
    font-size: 22px; }
  .single-deadline .days,
  .single-deadline .day {
    text-transform: lowercase; }
  .single-deadline .elapsed {
    color: #696e76;
    text-decoration: line-through; }
  @media screen and (min-width: 551px) {
    .single-deadline:hover {
      box-shadow: 0px 0px 15px 0px #e4e4e4;
      border-radius: 20px;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      background-color: #fff;
      color: black; } }

.elapsed:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background-color: #fefafc; }

#challenges {
  position: relative;
  padding-bottom: 10vw;
  background-color: #15766a;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  #challenges .section-heading {
    padding-top: 100px;
    margin-bottom: 2vw;
    color: white; }
  #challenges .section-intro {
    color: #ffffff;
    font-size: 16.875px;
    width: 960px;
    max-width: 80vw;
    margin: 2vw auto; }
  #challenges .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    width: 80%; }
    @media screen and (max-width: 500px) {
      #challenges .container {
        display: block;
        width: 100%; } }

.challenge {
  background-color: #111827;
  box-shadow: 0px 0px 15px 0px #e4e4e4;
  margin: 1vw;
  border-radius: 20px;
  width: 27vw;
  min-width: 300px;
  padding: 2vw;
  color: white;
  filter: alpha(opacity=60);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-self: stretch;
  padding-bottom: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; }
  .challenge:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  @media screen and (max-width: 750px) {
    .challenge {
      display: block;
      width: 80%;
      margin: 0px auto;
      margin-top: 30px;
      padding: 7.5vw; } }
  .challenge img {
    margin-bottom: 2vw;
    display: block;
    width: 50%;
    margin-left: auto;
    margin-right: auto; }
  .challenge h1 {
    font-size: 22.5px;
    text-align: center;
    text-transform: lowercase;
    margin-bottom: 2vw;
    font-weight: 700; }
  .challenge p {
    font-size: 17px;
    text-align: center; }

#about,
#application {
  padding-bottom: 7.5vw;
  position: relative;
  background: #111827;
  padding-bottom: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw; }
  #about .section-heading,
  #application .section-heading {
    margin-bottom: 2vw; }
  #about .hoover,
  #application .hoover {
    position: absolute;
    bottom: -4%;
    left: 2%;
    height: 70%;
    display: none; }
    @media screen and (max-width: 1600px) {
      #about .hoover,
      #application .hoover {
        display: none; } }
  #about .container,
  #application .container {
    max-width: 980px;
    margin: 0 auto;
    color: #696e76; }
    @media screen and (max-width: 960px) {
      #about .container,
      #application .container {
        margin: 0 7.5vw; } }
    #about .container .section-intro,
    #application .container .section-intro {
      margin: 5vh 0 0;
      padding: 0; }
    #about .container .side-image,
    #application .container .side-image {
      max-width: 200px;
      padding-left: 20px; }
      #about .container .side-image.pull-right,
      #application .container .side-image.pull-right {
        float: right; }
    #about .container p.content-text,
    #application .container p.content-text {
      font-size: 17px;
      margin: 2vw 0; }
      #about .container p.content-text.apply,
      #application .container p.content-text.apply {
        text-align: center; }
        #about .container p.content-text.apply .apply-button,
        #application .container p.content-text.apply .apply-button {
          display: inline-block;
          margin: 0 auto; }
    #about .container .about-video,
    #application .container .about-video {
      margin: 0 auto;
      width: 100%;
      max-width: 640px;
      border-radius: 1rem;
      overflow: hidden;
      -webkit-transition: 0.2s all;
      transition: 0.2s all;
      cursor: pointer;
      -webkit-transform: translateY(20%);
              transform: translateY(20%); }
      #about .container .about-video:hover,
      #application .container .about-video:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05); }
      #about .container .about-video > div,
      #application .container .about-video > div {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0; }
        #about .container .about-video > div iframe,
        #application .container .about-video > div iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
  #about .intro,
  #application .intro {
    padding: 5vh 0;
    font-size: 22.5px;
    color: white;
    text-align: center;
    font-weight: 700; }
  #about #stats,
  #application #stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px; }
  #about #statCard1,
  #application #statCard1 {
    padding: 30px;
    align-items: center;
    background-color: #10c695;
    color: white;
    font-size: 18px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px; }
    @media screen and (max-width: 1000px) {
      #about #statCard1,
      #application #statCard1 {
        padding: 20px;
        font-size: 10px; } }
    @media screen and (max-width: 650px) {
      #about #statCard1,
      #application #statCard1 {
        padding: 10px;
        font-size: 5px; } }
  #about #statNum,
  #application #statNum {
    font-size: 35px; }
    @media screen and (max-width: 1000px) {
      #about #statNum,
      #application #statNum {
        font-size: 20px; } }
    @media screen and (max-width: 650px) {
      #about #statNum,
      #application #statNum {
        font-size: 10px; } }
  #about #floatingLeaves,
  #application #floatingLeaves {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: row;
    z-index: 1; }
  #about #wrapper,
  #application #wrapper {
    position: relative; }
  #about #statCard2,
  #application #statCard2 {
    padding: 30px;
    align-items: center;
    background-color: #cfcfcf;
    color: #2a2e35;
    text-align: center;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    border-radius: 20px; }
    @media screen and (max-width: 1000px) {
      #about #statCard2,
      #application #statCard2 {
        padding: 20px;
        font-size: 10px; } }
    @media screen and (max-width: 650px) {
      #about #statCard2,
      #application #statCard2 {
        padding: 10px;
        font-size: 5px; } }
  #about #statWrapper,
  #application #statWrapper {
    display: flex;
    flex-direction: column;
    height: 100%; }
  #about #statRow,
  #application #statRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center; }
    @media screen and (max-width: 650px) {
      #about #statRow,
      #application #statRow {
        margin-bottom: 20px;
        grid-gap: 20px;
        gap: 20px; } }
  #about .body,
  #application .body {
    margin: 5vh 0;
    font-size: 17px;
    text-align: center; }
    @media screen and (min-width: 960px) {
      #about .body,
      #application .body {
        text-align: center;
        color: white; } }
  #about section,
  #application section {
    position: relative;
    width: 100%;
    height: 115vh;
    overflow: hidden; }
    @media screen and (max-width: 1000px) {
      #about section,
      #application section {
        height: 80vh; } }
    @media screen and (max-width: 650px) {
      #about section,
      #application section {
        height: 35vh; } }
    @media screen and (max-width: 500px) {
      #about section,
      #application section {
        height: 40vh; } }
    @media screen and (max-width: 400px) {
      #about section,
      #application section {
        height: 30vh; } }
    @media screen and (max-width: 380px) {
      #about section,
      #application section {
        height: 40vh; } }
  #about section .set,
  #application section .set {
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
    pointer-events: none; }
  #about section .set div,
  #application section .set div {
    position: absolute;
    display: block; }
  #about section .set div:nth-child(1),
  #application section .set div:nth-child(1) {
    left: 20%;
    -webkit-animation: animate 15s linear infinite;
            animation: animate 15s linear infinite;
    -webkit-animation-delay: -7s;
            animation-delay: -7s; }
  #about section .set div:nth-child(2),
  #application section .set div:nth-child(2) {
    left: 50%;
    -webkit-animation: animate 20s linear infinite;
            animation: animate 20s linear infinite;
    -webkit-animation-delay: -5s;
            animation-delay: -5s; }
  #about section .set div:nth-child(3),
  #application section .set div:nth-child(3) {
    left: 70%;
    -webkit-animation: animate 20s linear infinite;
            animation: animate 20s linear infinite;
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  #about .set2,
  #application .set2 {
    -webkit-transform: scale(2) rotateY(180deg);
            transform: scale(2) rotateY(180deg);
    -webkit-filter: blur(2px);
            filter: blur(2px); }
  #about .set3,
  #application .set3 {
    -webkit-transform: scale(2) rotateX(180deg);
            transform: scale(2) rotateX(180deg);
    -webkit-filter: blur(2px);
            filter: blur(2px); }
  #about section .set div:nth-child(4),
  #application section .set div:nth-child(4) {
    left: 0%;
    -webkit-animation: animate 15s linear infinite;
            animation: animate 15s linear infinite;
    -webkit-animation-delay: -5s;
            animation-delay: -5s; }
  #about section .set div:nth-child(5),
  #application section .set div:nth-child(5) {
    left: 85%;
    -webkit-animation: animate 19s linear infinite;
            animation: animate 19s linear infinite;
    -webkit-animation-delay: -10s;
            animation-delay: -10s; }
  #about section .set div:nth-child(6),
  #application section .set div:nth-child(6) {
    left: 0%;
    -webkit-animation: animate 12s linear infinite;
            animation: animate 12s linear infinite; }
  #about section .set div:nth-child(7),
  #application section .set div:nth-child(7) {
    left: 15%;
    -webkit-animation: animate 14s linear infinite;
            animation: animate 14s linear infinite; }
  #about section .set div:nth-child(8),
  #application section .set div:nth-child(8) {
    left: 60%;
    -webkit-animation: animate 15s linear infinite;
            animation: animate 15s linear infinite; }

@-webkit-keyframes animate {
  0% {
    opacity: 0;
    top: -10%;
    -webkit-transform: translateX(20px) rotate(0deg);
            transform: translateX(20px) rotate(0deg); }
  10% {
    opacity: 1; }
  20% {
    -webkit-transform: translateX(-20px) rotate(45deg);
            transform: translateX(-20px) rotate(45deg); }
  40% {
    -webkit-transform: translateX(-20px) rotate(90deg);
            transform: translateX(-20px) rotate(90deg); }
  60% {
    -webkit-transform: translateX(20px) rotate(180deg);
            transform: translateX(20px) rotate(180deg); }
  80% {
    -webkit-transform: translateX(-20px) rotate(180deg);
            transform: translateX(-20px) rotate(180deg); }
  100% {
    top: 110%;
    -webkit-transform: translateX(-20px) rotate(225deg);
            transform: translateX(-20px) rotate(225deg); } }

@keyframes animate {
  0% {
    opacity: 0;
    top: -10%;
    -webkit-transform: translateX(20px) rotate(0deg);
            transform: translateX(20px) rotate(0deg); }
  10% {
    opacity: 1; }
  20% {
    -webkit-transform: translateX(-20px) rotate(45deg);
            transform: translateX(-20px) rotate(45deg); }
  40% {
    -webkit-transform: translateX(-20px) rotate(90deg);
            transform: translateX(-20px) rotate(90deg); }
  60% {
    -webkit-transform: translateX(20px) rotate(180deg);
            transform: translateX(20px) rotate(180deg); }
  80% {
    -webkit-transform: translateX(-20px) rotate(180deg);
            transform: translateX(-20px) rotate(180deg); }
  100% {
    top: 110%;
    -webkit-transform: translateX(-20px) rotate(225deg);
            transform: translateX(-20px) rotate(225deg); } }

#projects {
  position: relative;
  background-color: #111827;
  padding-bottom: 4vw;
  padding-top: 4vw; }
  #projects .section-heading {
    padding-top: 2vw;
    margin-bottom: 2vw;
    font-size: 5vw;
    color: #ffffff; }
    @media screen and (max-width: 750px) {
      #projects .section-heading {
        padding-bottom: 10px; } }
  #projects #slider-container {
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    /* Using flexbox */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    /*----*/ }
  #projects .container {
    max-width: 960px;
    margin: 7.5vw auto; }
  #projects #hook-text {
    color: transparent;
    background: -webkit-radial-gradient(50% 50%, 50% 50%, #faff00 0%, #ff9900 100%);
    background: radial-gradient(50% 50% at 50% 50%, #faff00 0%, #ff9900 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; }
  @media screen and (max-width: 750px) {
    #projects .grid-item {
      width: 68vw; } }

.slick-track {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center; }

#project-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 1%;
  justify-content: center;
  width: 65%;
  grid-gap: 20px;
  gap: 20px; }
  @media screen and (max-width: 750px) {
    #project-container {
      width: 60%; } }

#text-container {
  display: flex;
  width: 27%;
  margin-left: 3%;
  flex-direction: column;
  align-items: center; }
  @media screen and (max-width: 750px) {
    #text-container {
      width: 35%; } }

.flip-card {
  width: 280px;
  height: 400px;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
  @media screen and (max-width: 750px) {
    .flip-card {
      width: 90px;
      height: 150px; } }

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  -webkit-backface-visibility: hidden;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden; }
  @media screen and (max-width: 750px) {
    .flip-card-front,
    .flip-card-back {
      font-size: 4.5px; }
      .flip-card-front #projectImage,
      .flip-card-back #projectImage {
        width: 30px !important; } }

.flip-card-front {
  color: white; }

.flip-card-back {
  background-color: #2980b9;
  background: -webkit-radial-gradient(#000000, #0b9872);
  background: radial-gradient(#000000, #0b9872);
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.body {
  margin: 5vh 0;
  margin-top: 0px;
  font-size: 19px;
  text-align: center;
  color: white; }
  @media screen and (max-width: 750px) {
    .body {
      font-size: 11px; } }

#faq {
  position: relative;
  margin: 7.5vw 2vw;
  width: 100vw;
  background: #111827;
  margin: auto;
  font-size: 1.1rem;
  display: flex;
  padding-top: 8vw;
  flex-direction: column;
  align-content: center; }
  #faq .section-heading {
    margin-bottom: 2vw;
    margin-right: 10px;
    margin-left: 10px;
    color: white; }
  #faq .faq-plus {
    margin-right: 20px;
    width: 50px;
    height: 50px; }
  #faq .section-subheading {
    margin-bottom: 25px; }
  #faq .faq-question,
  #faq .faq-answer {
    color: white;
    text-align: left; }
  #faq .faq-question {
    display: flex;
    margin-bottom: 10px;
    align-items: center; }
    @media screen and (max-width: 750px) {
      #faq .faq-question {
        font-size: 4vw; } }
  #faq .faq-answer {
    margin-bottom: 15px;
    -webkit-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden; }
  #faq .faq-container {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column; }
  #faq .faq-item {
    padding: 1.6vw; }
    @media screen and (max-width: 1023px) {
      #faq .faq-item {
        flex-basis: 100%; } }
  #faq .body {
    margin: 5vh 0;
    font-size: 21px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    color: white; }

#sponsors.container {
  background: #111827;
  padding-bottom: 2vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 300px;
  justify-content: center; }
  #sponsors.container .section-heading {
    margin-bottom: 2vw;
    font-size: 60px; }
  #sponsors.container .body {
    margin: 5vh 0;
    margin-top: 20px;
    margin-right: 100px;
    margin-left: 100px;
    font-size: 23px;
    text-align: center; }
  #sponsors.container #sub-heading {
    margin-bottom: 1.5rem;
    color: white; }
  @media screen and (max-width: 750px) {
    #sponsors.container {
      width: 100%;
      margin-left: 0px; } }
  #sponsors.container .level {
    margin: 1rem auto; }
    #sponsors.container .level img:hover {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    #sponsors.container .level.level-biggest img {
      background-color: white;
      width: 600px;
      padding: 20px;
      border-radius: 20px; }
    #sponsors.container .level.level-big img {
      background-color: white;
      border-radius: 20px;
      padding: 20px;
      width: 300px;
      height: 80px; }
    #sponsors.container .level.level-medium img {
      background-color: white;
      padding: 20px;
      border-radius: 20px;
      width: 166px;
      height: 60px; }
    #sponsors.container .level.level-small img {
      background-color: white;
      padding: 20px;
      border-radius: 20px;
      width: 90px;
      height: 40px; }
    #sponsors.container .level.level-smallest {
      margin-top: 2rem; }
    #sponsors.container .level.level-smallest img {
      width: 100%; }
    #sponsors.container .level.level-smallest a {
      width: 10%;
      min-width: 50px;
      margin: 0 10px; }
    #sponsors.container .level.level-tiny {
      margin-bottom: 3rem; }
    #sponsors.container .level.level-tiny img {
      width: 100%; }
    #sponsors.container .level.level-tiny a {
      width: 6%;
      min-width: 50px;
      margin: 0 10px; }
    #sponsors.container .level a {
      margin: 0rem; }

@media screen and (max-width: 750px) {
  #linkText {
    font-size: 15px; } }

.emailSignup form {
  padding: 3vh 0;
  margin-bottom: 1rem; }
  .emailSignup form div[aria-hidden="true"] {
    position: absolute;
    left: -5000px; }
  .emailSignup form #mce-EMAIL {
    background-color: inherit;
    border: none;
    border-bottom: 1.5px solid black;
    border-radius: 0;
    color: black;
    width: 400px;
    max-width: 80vw;
    display: block;
    font-family: "Poppins", proxima-nova, sans-serif;
    font-size: 14pt; }
    .emailSignup form #mce-EMAIL::-webkit-input-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL::-moz-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL:-ms-input-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL::-ms-input-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL::placeholder {
      color: #696e76;
      opacity: 1; }
  .emailSignup form #mce-EMAIL:focus {
    outline: 0; }
  .emailSignup form #mc-embedded-subscribe {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    width: 100%;
    max-width: 80vw;
    cursor: pointer;
    -webkit-appearance: none;
    white-space: normal; }

::-webkit-input-placeholder {
  color: #e0e0e0;
  font-style: italic; }

::-moz-placeholder {
  color: #e0e0e0;
  font-style: italic; }

:-ms-input-placeholder {
  color: #e0e0e0;
  font-style: italic; }

::-ms-input-placeholder {
  color: #e0e0e0;
  font-style: italic; }

::placeholder {
  color: #e0e0e0;
  font-style: italic; }

body {
  overflow-x: hidden; }

@media screen and (max-width: 1023px) {
  .stripe, .stripe-wrapper {
    display: none; } }

.stripe {
  background-image: -webkit-radial-gradient(#edf5ee 30%, #ffffff00 20%);
  background-image: radial-gradient(#edf5ee 30%, #ffffff00 20%);
  background-position: 0 0;
  background-size: 20px 20px;
  height: 200px;
  width: 120vw;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
  left: -50px;
  position: absolute;
  z-index: -1;
  margin-top: -16%; }
  @media screen and (max-width: 400px) {
    .stripe {
      display: none; } }
  .stripe.accent-blue {
    background-image: -webkit-radial-gradient(#A7DDE8 30%, #ffffff00 20%);
    background-image: radial-gradient(#A7DDE8 30%, #ffffff00 20%); }
  .stripe.accent-pink {
    background-image: -webkit-radial-gradient(#E51B5D 30%, #ffffff00 20%);
    background-image: radial-gradient(#E51B5D 30%, #ffffff00 20%); }
  .stripe.accent-orange {
    background-image: -webkit-radial-gradient(#ffc623 30%, #ffffff00 20%);
    background-image: radial-gradient(#ffc623 30%, #ffffff00 20%); }

.stripe-wrapper {
  margin: 10vw 0 200px; }
  .stripe-wrapper.sponsors {
    margin: 12vw 0 1vw; }

.floating-illustration {
  position: absolute;
  top: 4%;
  left: 0;
  right: 0; }
  @media screen and (max-width: 1023px) {
    .floating-illustration {
      display: none; } }
  .floating-illustration img {
    position: absolute;
    top: 0;
    right: 3%;
    width: 15vw;
    min-width: 150px;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: updown;
            animation-name: updown;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease; }
  .floating-illustration.parachute img {
    width: 20vw;
    margin-top: -200px;
    left: 5%;
    right: auto; }
  .floating-illustration.laptop img {
    width: 20vw;
    margin-top: -220px;
    left: 5%;
    left: auto; }

@-webkit-keyframes updown {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes updown {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes leftright {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes leftright {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

#why {
  position: relative;
  background: #111827; }
  #why .section-heading {
    margin-bottom: 2vw; }
  #why #overlaycontainer {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    color: white;
    font-size: 3vw;
    position: absolute;
    background: black;
    justify-content: center;
    opacity: 0.6;
    text-align: left;
    padding: 5%; }
  #why #videocontainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%; }
  #why .container {
    margin: 0 auto;
    color: #696e76; }
    #why .container .section-intro {
      margin: 5vh 0 0;
      padding: 0; }
    #why .container .side-image {
      max-width: 200px;
      padding-left: 20px; }
      #why .container .side-image.pull-right {
        float: right; }
    #why .container p.content-text {
      font-size: 17px;
      margin: 2vw 0; }
      #why .container p.content-text.apply {
        text-align: center; }
        #why .container p.content-text.apply .apply-button {
          display: inline-block;
          margin: 0 auto; }
    #why .container .about-video {
      margin: 0 auto;
      width: 100%;
      max-width: 640px;
      border-radius: 1rem;
      overflow: hidden;
      cursor: pointer; }
      #why .container .about-video > div {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0; }
        #why .container .about-video > div iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
  #why .body {
    margin: 5vh 0;
    font-size: 17px;
    text-align: center; }
    @media screen and (min-width: 960px) {
      #why .body {
        text-align: center;
        color: white; } }

