#faq {
  position: relative;
  margin: $large-space $small-space;
  width: 100vw;
  //background: #2a2e35;
  background: $dark-mode;
  margin: auto;
  font-size: 1.1rem;
  display: flex;
  padding-top: $small-space * 4;
  flex-direction: column;
  align-content: center;

  .section-heading {
    margin-bottom: $small-space;
    margin-right: 10px;
    margin-left: 10px;
    color: white;
  }

  .faq-plus {
    margin-right: 20px;
    width: 50px;
    height: 50px;
  }

  .section-subheading {
    margin-bottom: 25px;
    // cursor: pointer;
  }

  .faq-question,
  .faq-answer {
    color: white;
    text-align: left;
  }

  .faq-question {
    display: flex;
    @media screen and (max-width: 750px) {
      font-size: 4vw;
    }
    margin-bottom: 10px;
    align-items: center;
  }

  .faq-answer {
    margin-bottom: 15px;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
  }

  .faq-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .faq-item {
    padding: $small-space/1.25;

    @media screen and (max-width: 1023px) {
      flex-basis: 100%;
    }
  }

  .body {
    margin: $medium-space 0;
    font-size: $medium-font-size + 4;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
  }
}
